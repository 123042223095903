import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function SolutionHaircare() {
	
	return (
		<Article
			title='Identifying harmful hair care products'
			takeaway_title='Avoid hair care products with any of the following ingredients'
			page_id='Solution - Haircare'
			read_time='12 mins'
			author='Brij Bhushan'
			image1='/harmful.jpg'
			image1_description='Several ill effects of harmful chemicals'
			image2='/junk.jpg'
			image2_description='Texture & fragrance of harmful hair care products excite our senses but their impact affects health of the body in long run'
			toggle_article='Blog'
			toggle_url='/article/blog_haircare'
			related_article='Blog : Haircare'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
				<div className='article_container'>
					<p>
						If you look at ingredients of a shampoo or conditioner, you will typically find a long list of chemicals. Some of the chemicals can be harmful for the health of hair & scalp. In this article, we will discuss why they are added and what harm they can cause. The harmful effects are mostly dryness of hair/scalp, skin inflammation, lifeless & dull hair in the long run. However, in some cases they can cause serious conditions like cancer also. <span className="super">[1], [2]</span>
					</p>
					<p>
						These chemicals are added for various reasons. Foaming agents are added to give a rich-lather. Thickening agents are added so that you have a thick & creamy shampoo, instead of a watery one. Chelating agents are added so that you can have good satisfaction with a shampoo even when used with hard water. Conditioning agents are meant to leave a smooth & silky texture on your hair. Preservatives are to increase the shelf life of the products, otherwise they may get bacterial or fungal growth sooner than later. In short, the chemicals are for your better experience and longer shelf life. <span className="super">[1]</span>
					</p>
					<p>
						You must be wondering if some of these chemicals can be so harmful, then why they are allowed to be used. Side effects like dryness, minor inflammation are not noticeable in the short run. They may become noticeable only after using a product for a few months. They may trigger problems like dandruff, acne & hair loss after using them over a year. Moreover, the magnitude of the problem may vary from person to person. In the technical evaluation, these chemicals escape with reasons like they are not much harmful, when washed off within a few minutes or they can be harmful only if exposure is in large quantities. In short, these harmful chemicals are added in low concentrations, so their ill effects are not visible immediately, rather they work as minor irritants which give you visible problems over years of regular use. It is similar to eating a diet of burgers & fries, which in short run stimulates your taste buds but over a long run erodes your body & gives health problems. <span className="super">[3], [4]</span>
					</p>
					<p>
						So, one should avoid using a shampoo or conditioner which has any of the following chemicals:
					</p>
					<h3 className='article_heading'>Sodium Lauryl Sulfate (SLS)</h3>
					<p>
						It is called a surfactant. When a surfactant is added it reduces surface tension, so that oils & dirt can be washed off. It is popular to be used in shampoos as well as harsh detergents. It is cheap and it forms a lot. It can cause separation of skin layers & inflammation of skin. It can also denature proteins of eye tissues. While cleansing, it can also damage the hair, make it brittle, and increase frizz. Other side effects are dryness, roughness, scaliness and hair loss. <span className="super">[3], [5], [6], [7]</span>
					</p>
					<h3 className='article_heading'>Sodium Laureth Sulfate (SLES)</h3>
					<p>
						It is also a surfactant. It is short for Sodium Lauryl Ether Sulfate. It is prepared from SLS itself by reacting it with Ethylene Oxide. It is considered slightly gentle as compared with SLS. However, in the long run, its negative effects are the same as the SLS. Other similar chemicals are ammonium lauryl sulfate, ammonium laureth sulfate, and sodium C14-16 olefin sulfonate.<span className="super">[6], [8], [9]</span>
					</p>
					<h3 className='article_heading'>MEA, DEA & TEA chemicals</h3>
					<p>
						In general this category is referred to as amine and amine derivatives. These are Monoethanolamine (MEA), Triethanolamine (TEA), Diethanolamine (DEA) based chemicals. Cocamide MEA is one such chemical. They are foaming agents and can also make water-soluble & oil-soluble ingredients blend together. <span className="super">[6], [10]</span>
					</p>
					<p>
						DEA can produce nitrosamines, which causes cancer. Similarly, MEA & TEA chemicals can also react with other ingredients to produce nitrosamines and cause cancer. <span className="super">[1], [11], [12]</span>
					</p>
					<p>
						Common chemicals of this type are Cocamide MEA, Cocamide DEA, Cocamide TEA, Oleamide MEA, Oleamide DEA, Oleamide TEA, Lauramide MEA, Lauramide DEA, Lauramide TEA, Linoleamide DEA, Myristamide DEA, Stearamide DEA, DEA-Cetyl Phosphate, DEA-Oleth-3 Phosphate and TEA-Lauryl Sulfate. <span className="super">[10]</span>
					</p>
					<h3 className='article_heading'>PEG-n</h3>
					<p>
						PEGs (Poly-Ethylene Glycols) are petroleum-based compounds that are used as thickening agents, since we like our shampoos thick and creamy. The primary concern around PEGs is not the PEG themselves, but its possible carcinogenic contaminants during the manufacturing process. PEGs are often contaminated with ethylene oxide, a known human carcinogen and 1,4-dioxane, a suspected carcinogen. They can cause skin toxicity if applied on cuts or dry skin. 
					</p>
					<p>
						PEG-6, PEG-20, PEG-75 are its examples. Stearth-n (like Stearth-6) and Trideceth-n (like Trideceth-3, 10 & 12) are also referred as Poly-Ethylene Glycol ethers. <span className="super">[1], [13]</span>
					</p>
					<h3 className='article_heading'>Carbomer</h3>
					<p>
						Carbomer refers to polyacrylic acid (PAA). It is used as a thickening agent. They are generally considered as safe but at high concentrations can lead to eye and skin irritation. Even if manufacturers adjust their strength appropriately, the substances used for neutralizing their pH often have their own set of safety concerns. Some of the examples are Triethanolamine (TEA), Sodium hydroxide, Potassium hydroxide, and Tetrasodium EDTA. If the manufacturer purchases pre-neutralized carbomers, then it may not mention the neutralizing agent. <span className="super">[14], [15]</span>
					</p>
					<h3 className='article_heading'>Sodium Sulphate</h3>
					<p>
						It is a thickening agent. Like other sulphates it is also a skin irritant. <span className="super">[28]</span>
					</p>
					<h3 className='article_heading'>Tetrasodium  EDTA</h3>
					<p>
						It stands for Tetrasodium Ethylene-Diamine-Tetraacetic Acid. It is made from Formaldehyde (a known carcinogen) and Sodium cyanide. It is believed that after these two harmful chemicals react with each other, their ill-effects on humans subside to some extent. It is a chelating agent. It means it is a chemical which binds with certain minerals, such that one can enjoy lather even with hard water. The same binding action makes it a penetration enhancer. That means it breaks down the skin’s protective barrier and makes it easier for other potentially harmful ingredients to sink deeper into your tissues and perhaps even into your bloodstream. In high concentration, it can bind with essential minerals of the body & cause problems like zinc deficiency, which can further erode your skin, muscles & even immunity. Definitely, not advisable for pregnant women. It is also known to be a potent eye irritant.
					</p>
					<p>
						To avoid this ingredient, also look for these names in the ingredients list; Edetate sodium,
Tetrasodium edetate, Tetrasodium salt, TEA-EDTA. <span className="super">[16]</span>
					</p>
					<h3 className='article_heading'>Disodium EDTA</h3>
					<p>
						It is also a chelating agent, which helps in making froth with hard water. It also helps in maintaining clarity & texture of the shampoo which increases its shelf life. It is also referred to as E385. It also acts as a penetration enhancer & can help other harmful ingredients to freely absorb into the skin. Several countries, including Australia and parts of Europe, have banned the ingredient because of potential health problems from overexposure. <span className="super">[17]</span>
					</p>
					<h3 className='article_heading'>Propylene Glycol</h3>
					<p>
						It is a humectant. It prevents moisture from leaving the shampoo. Basically, it keeps the shampoo or conditioner from drying out. Also it is cheap and readily available. It can absorb moisture from skin. It is also a penetration enhancer. Therefore, it can help pollutants deposited on skin and other chemicals to penetrate in tissue & bloodstream. Therefore, it can cause dryness, skin irritation and many other problems. <span className="super">[3], [18], [19]</span>
					</p>
					<h3 className='article_heading'>Dimethicone</h3>
					<p>
						It is a conditioning agent. It is often referred to as silicones. Other popular silicones are amodimethicone, cyclomethicone, methicone, dimethiconol, cyclomethicone & cyclopentasiloxane. These are very popular ingredients of hair conditioners. These days, they are found in hair shampoos as well. It gives these products a smooth & silky texture.
					</p>
					<p>
						When applied, it gives your hair a smooth & shiny look. However, it is a fake shine like plastic. The real shine comes to hair when its cuticle layer is sealed and it reflects light, which happens only when hair is properly hydrated and healthy. Silicones rather create an artificial layer which prevents moisture from penetrating the hair shaft and with regular use the hair becomes dry, frizzy, brittle and lifeless. <span className="super">[2]</span>
					</p>
					<h3 className='article_heading'>Polyquaternium-n</h3>
					<p>
						It is also a conditioning agent. It gets absorbed into the proteins of hairs and improves their manageability by making them smoother. However, it releases Formaldehyde (which is a known carcinogen). Since it can cause cancer, therefore, it is harmful. <span className="super">[20]</span>
					</p>
					<h3 className='article_heading'>Behentrimonium Chloride</h3>
					<p>
						It is a conditioning agent. It also works as a preservative. It can cause damage to the tissues of the mucous membrane, therefore it is harmful for eyes. It is also a skin irritant and can cause an increased risk of cancer, nerve damage, anemia and disintegration of the kidneys. <span className="super">[29]</span>
					</p>
					<h3 className='article_heading'>Methyl-chloro-isothiazalinone (MCI)</h3>
					<p>
						It is a preservative that works against bacteria and fungi. It is added to increase the shelf life. When used regularly, it can cause skin irritation and allergic reaction.
					</p>
					<p>
						It can also be found with its other names; 5-chloro-2-methyl-4-isothiazolin-3-one, 5-chloro-2-methyl-4-isothiazolin-3-one hydrochloride, 5-chloro-2-methyl isothiazolin-3-one, 5-chloro-N-methylisothiazolone and Kathon CG 5243. <span className="super">[21]</span>
					</p>
					<h3 className='article_heading'>Methyl-isothiazalinone (MI)</h3>
					<p>
						It is also a preservative. Its function is to inhibit the growth of bacteria. The objective is to increase the shelf life. It is linked to problems like lung toxicity, neurotoxicity and allergic reactions. <span className="super">[22]</span>
					</p>
					<p>
						It can also be found with its other names; 3(2H)-Isothiazolone-2-methyl, 2-methyl-2H-isothiazol-3-one, 2-methyl-4-isothiazolin-3-one and 2-methyl-3(2H)-isothiazolone.
					</p>
					<h3 className='article_heading'>DMDM Hydantoin</h3>
					<p>
						It is also a preservative. It prevents the growth of bacteria and fungi, to increase the shelf life. However, it releases small amounts of Formaldehyde throughout the shelf-life. Formaldehyde is a known carcinogen. In addition to being carcinogenic, Formaldehyde & its releasers can cause allergic reactions, including skin irritations and asthma. <span className="super">[23], [24]</span>
					</p>
					<h3 className='article_heading'>Cetrimonium Chloride</h3>
					<p>
						It is also a preservative, which is used to increase the shelf life. It potentially strips off the protective layer of your hair and makes it vulnerable to damage. Using it for a long time can dehydrate the hairs making them dry. <span className="super">[25]</span>
					</p>
					<h3 className='article_heading'>BHT</h3>
					<p>
						BHT is short for Butylated HydroxyToluene. It is a kind of preservative which works as a stabilizing agent in shampoo. It has been linked with lung toxicity when inhaled. <span className="super">[26], [27]</span>
					</p>
					<h3 className='article_heading'>Hydroxycitronellal</h3>
					<p>
						It is a fragrance ingredient. It causes serious eye irritation and may cause an allergic skin reaction. <span className="super">[30]</span>
					</p>
					<p>
						Other chemicals which are considered harmful in hair care products are fragrances and perfumes, which serve no use in hair care but often carry harmful chemicals. Methyl Paraben is another harmful chemical. Since I couldn’t find it among the ingredients of the popular products, therefore not including it in the list. Cocamidopropyl Betaine and Phenoxyethanol are also reported to cause eye & skin irritation. This list of harmful chemicals is not exhaustive. However, if your shampoo or conditioner has even one of these, then you should reconsider using it. <span className="super">[1], [9]</span>
					</p>
					<p>
						PS: To learn about how I fell prey to these harmful haircare products and how finally I moved out of their vicious cycle, you can read my blog <Link to='/article/blog_haircare'>here</Link>.

					</p>
					<h3 className='article_heading'>References</h3>
					<ol className="reference_list">
						<li>
							Sayantani Chatterjee. 13 Harmful Ingredients To Avoid In Your Shampoo and Conditioner. Vedix. December 14, 2020. <Link to='https://vedix.com/blogs/articles/harmful-ingredients-in-shampoo-and-conditioner'>https://vedix.com/blogs/articles/harmful-ingredients-in-shampoo-and-conditioner</Link>
						</li>
						<li>
							Naomi Nachmani. The Truth About Silicone-Based Hair Products. Coveteur, Hair. <Link to='https://coveteur.com/2019/03/29/silicone-hair-products/'>https://coveteur.com/2019/03/29/silicone-hair-products/</Link>
						</li>
						<li>
							C. Prottey. The molecular basis of skin irritation. Cosmetic Science, 1978. <Link to='http://www.healthychoices.co.uk/Skin_Irritability.pdf'>http://www.healthychoices.co.uk/Skin_Irritability.pdf</Link>
						</li>
						<li>
							Sonia C. DaSilva, Ravi P. Sahu, Raymond L. Konger, et. al. Increased skin barrier disruption by sodium lauryl sulfate in mice expressing a constitutively active STAT6 in T cells. Archives of Dermatological Research, volume 304, pages 65–71. 2012. <Link to='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3249512/'>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3249512/</Link>
						</li>
						<li>
							Healthy Choices. Sodium Lauryl Sulphate. [Accessed <Link to='http://www.healthychoices.co.uk/sls.html'>http://www.healthychoices.co.uk/sls.html</Link>
						</li>
						<li>
							Nutrafol. 15 Harmful Shampoo Ingredients to Avoid. Apr 16, 2018. <Link to='https://nutrafol.com/blog/15-shampoo-ingredients-to-avoid/'>https://nutrafol.com/blog/15-shampoo-ingredients-to-avoid/</Link>
						</li>
						<li>
							Sodium Lauryl Sulfate - Sulfates. JES Organics. <Link to='https://www.jesorganics.com/solasusu.html'>https://www.jesorganics.com/solasusu.html</Link>
						</li>
						<li>
							Wikipedia. Sodium laureth sulfate. <Link to='https://en.wikipedia.org/wiki/Sodium_laureth_sulfate'>https://en.wikipedia.org/wiki/Sodium_laureth_sulfate</Link>
						</li>
						<li>
							Soultree. 5 harmful chemicals in shampoo & conditioners, you should avoid. <Link to='https://www.soultree.in/blogs/hair-care/look-out-for-these-harmful-chemicals-in-your-shampoo'>https://www.soultree.in/blogs/hair-care/look-out-for-these-harmful-chemicals-in-your-shampoo</Link>
						</li>
						<li>
							Francesca Clemente. Short list of harmful cosmetic ingredients. Lifegate, Health. January 7, 2010. <Link to='https://www.lifegate.com/harmful-cosmetic-ingredients'>https://www.lifegate.com/harmful-cosmetic-ingredients</Link>
						</li>
						<li>
							Final Report on the Safety Assessment of Cocamide MEA. International Journal of Toxicology. 18 (Suppl. 2): 9-16, 1999. <Link to='https://journals.sagepub.com/doi/pdf/10.1177/109158189901800204'>https://journals.sagepub.com/doi/pdf/10.1177/109158189901800204</Link>
						</li>
						<li>
							Campaign for safe cosmetics. Nitrosamines. <Link to='https://www.safecosmetics.org/get-the-facts/chemicals-of-concern/nitrosamines/'>https://www.safecosmetics.org/get-the-facts/chemicals-of-concern/nitrosamines/</Link>
						</li>
						<li>
							Clearlife. Why Your Shampoo Might Be Contaminated With PEGs. <Link to='https://clearlifeinc.com/how-to-know-your-shampoo-is-contaminated/'>https://clearlifeinc.com/how-to-know-your-shampoo-is-contaminated/</Link>
						</li>
						<li>
							The Derm Review. Why Is Carbomer In So Many Products? Oct 13, 2020. <Link to='https://thedermreview.com/carbomer/'>https://thedermreview.com/carbomer/</Link>
						</li>
						<li>
							G. Robinson. What is a Carbomer? Beautyanswered.com <Link to='https://www.beautyanswered.com/what-is-a-carbomer.htm'>https://www.beautyanswered.com/what-is-a-carbomer.htm</Link>
						</li>
						<li>
							Annmarie. Ingredient Watch List: Tetrasodium EDTA in skin care, the Preservative Made from Formaldehyde. May 11, 2021. <Link to='https://www.annmariegianni.com/ingredient-watch-list-tetrasodium-edta-the-preservative-made-from-formaldehyde/'>https://www.annmariegianni.com/ingredient-watch-list-tetrasodium-edta-the-preservative-made-from-formaldehyde/</Link>
						</li>
						<li>
							Meta pora. Disodium Edta - Harmful Chemical To Avoid In Skin Care. Oct 10, 2020. <Link to='https://www.metapora.com/blogs/skin-care-ingredients/disodium-edta-harmful-chemical-to-avoid-in-skin-care'>https://www.metapora.com/blogs/skin-care-ingredients/disodium-edta-harmful-chemical-to-avoid-in-skin-care</Link>
						</li>
						<li>
							Hannah Mepham. The problem with propylene glycol in skin care. Green people Organic Lifestyle. May 20, 2020. <Link to='https://www.greenpeople.co.uk/blogs/the-beauty-hub/the-problem-with-propylene-glycol-in-skin-care'>https://www.greenpeople.co.uk/blogs/the-beauty-hub/the-problem-with-propylene-glycol-in-skin-care</Link>
						</li>
						<li>
							Bubble & Bee. Top Five Chemicals to Avoid. 2015. <Link to='https://bubbleandbee.com/top-five-chemicals-to-avoid/'>https://bubbleandbee.com/top-five-chemicals-to-avoid/</Link>
						</li>
						<li>
							Euronews.green. Cosmetics: which ingredients are a huge no-no? April 7, 2019. <Link to='https://www.euronews.com/green/2019/06/15/cosmetics-which-ingredients-are-a-huge-no-no'>https://www.euronews.com/green/2019/06/15/cosmetics-which-ingredients-are-a-huge-no-no</Link>
						</li>
						<li>
							Scott Frothingham. The Uses, Benefits, and Side Effects of Methylchloroisothiazolinone. Healthline. May 9, 2019. <Link to='https://www.healthline.com/health/methylchloroisothiazolinone'>https://www.healthline.com/health/methylchloroisothiazolinone</Link>
						</li>
						<li>
							Meghan J. The scoop on Methylisothiazolinone. Force of nature, Toxic chemical glossary, <Link to='https://www.forceofnatureclean.com/chemical-free-living-methylisothiazolinone/'>https://www.forceofnatureclean.com/chemical-free-living-methylisothiazolinone/</Link>
						</li>
						<li>
							Chemical safety facts. DMDM Hydantoin.  <Link to='https://www.chemicalsafetyfacts.org/dmdm-hydantoin-2/'>https://www.chemicalsafetyfacts.org/dmdm-hydantoin-2/</Link>
						</li>
						<li>
							Olivia Petter. Four harmful ingredients to avoid in your hair products. Independent. June 12, 2018. <Link to='https://www.independent.co.uk/life-style/hair-products-harmful-ingredients-explained-parabens-sulfates-chemicals-a8395561.html'>https://www.independent.co.uk/life-style/hair-products-harmful-ingredients-explained-parabens-sulfates-chemicals-a8395561.html</Link>
						</li>
						<li>
							Cetrimonium Chloride Bad For Hair : Effects Of Chloride On Hair, Helthco, Lifestyle. <Link to='https://helth.co/cetrimonium-chloride-bad-hair/'>https://helth.co/cetrimonium-chloride-bad-hair/</Link>
						</li>
						<li>
							Bauer AK, Dwyer-Nield LD, Keil K, Koski K, et. al. Butylated hydroxytoluene (BHT) induction of pulmonary inflammation: a role in tumor promotion. Experimental Lung Research. 2001 Apr-May;27(3):197-216. <Link to='https://pubmed.ncbi.nlm.nih.gov/11293324/'>https://pubmed.ncbi.nlm.nih.gov/11293324/</Link>
						</li>
						<li>
							Waseem M, Kaw JL. Pulmonary effects of butylated hydroxytoluene in mice. Food Additives & Contaminants. 1994 Jan-Feb;11(1):33-8. <Link to='https://doi.org/10.1080/02652039409374200'>https://doi.org/10.1080/02652039409374200</Link>
						</li>
						<li>
							Special Chem. Sodium Sulphate. <Link to='https://cosmetics.specialchem.com/inci/sodium-sulfate'>https://cosmetics.specialchem.com/inci/sodium-sulfate</Link>
						</li>
						<li>
							Lena Bratschi, Tom Rann. Toxic Timeout: Behentrimonium Chloride. The Chalkboard. February 26, 2013. <Link to='https://thechalkboardmag.com/toxic-tuesday-behentrimonium-chloride'>https://thechalkboardmag.com/toxic-tuesday-behentrimonium-chloride</Link>
						</li>
						<li>
							Curious Chloride. Hydroxycitronellal. September 3. 2019. <Link to='https://www.curiouschloride.com/substances/hydroxycitronellal/'>https://www.curiouschloride.com/substances/hydroxycitronellal/</Link>
						</li>
					</ol>
				</div>
		</Article>
	);
}

const TakeawayData = [
	{
		takeaway: 'Sodium Lauryl Sulfate (SLS)',
	},
	{
		takeaway: 'Sodium Laureth Sulfate (SLES)',
	},
	{
		takeaway: 'MEA, DEA & TEA chemicals',
	},
	{
		takeaway: 'PEG-n',
	},
	{
		takeaway: 'Carbomer',
	},
	{
		takeaway: 'Sodium Sulphate',
	},
	{
		takeaway: 'Tetrasodium EDTA',
	},
	{
		takeaway: 'Disodium EDTA',
	},
	{
		takeaway: 'Propylene Glycol',
	},
	{
		takeaway: 'Dimethicone',
	},
	{
		takeaway: 'Polyquaternium-n',
	},
	{
		takeaway: 'Behentrimonium Chloride',
	},
	{
		takeaway: 'Methyl-chloro-isothiazalinone (MCI)',
	},
	{
		takeaway: 'Methyl-isothiazalinone (MI)',
	},
	{
		takeaway: 'DMDM Hydantoin',
	},
	{
		takeaway: 'Cetrimonium Chloride',
	},
	{
		takeaway: 'BHT',
	},
	{
		takeaway: 'Hydroxycitronellal',
	},
];

const RelatedArticles = [
	{
		title: 'Blog - Haircare',
		path: '/article/blog_haircare',
	},
];

